import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "universal-cookie";
import URL_COLLECTION from "../../assets/data/URL";
import { ILoginRequest } from "./auth.interface";
import { tokenName, userNameItem } from "./data/Variables";

const AUTH_URL = URL_COLLECTION.scripts.main.auth;

export const userLogin = createAsyncThunk(
    "auth/login",
    async ({ login, password, isSave }: ILoginRequest, { rejectWithValue }) => {
        const cookies = new Cookies();
        cookies.remove(tokenName);
        localStorage.removeItem(userNameItem);

        try {
            const config = {
                headers: {
                    "Content-Type": "text/html",
                },
            };
            const authData = {
                method: "login",
                login,
                password,
            };
            const fd = new FormData();
            fd.append("authData", JSON.stringify(authData));

            const { data } = await axios.post(AUTH_URL, fd, config);
            const userToken = data.userToken;

            delete data.userToken;

            if (isSave) {
                cookies.set(tokenName, userToken, {
                    //   httpOnly: true,
                    maxAge: 2592000,
                });
                localStorage.setItem(userNameItem, JSON.stringify(data));
                axios.defaults.headers.common["Authorization"] = "Bearer" + userToken;
            }

            return { ...data, userToken };
        } catch (error: any) {
            // return custom error message from API if any

            if (error.response && error.response.data.message)
                return rejectWithValue(error.response.data.message);
            else return rejectWithValue(error.message);
        }
    }
);
