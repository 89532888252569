const protocol = "https";
const folder_scripts = "scripts/";

const sites = {
    admin: `${protocol}://club.clubok24.ru/`,
    main: `https://clubok24.ru/`,
    video: "https://video.clubok24.ru/",
    news: "https://super-story.ru/",
};
const url_scripts = `${sites.admin}${folder_scripts}`;
const media_items = `${sites.admin}content/`;
const media_items_original = `${sites.admin}content/original/`;
const zennolab_main = `${sites.admin}zennolab/`;

const URL_COLLECTION = {
    sites,
    url_scripts,
    media_items,
    media_items_original,
    scripts: {
        main: {
            url_scripts,
            auth: `${url_scripts}auth.php`,
            partitions: `${url_scripts}main.php`,
            get_item_editview: `${url_scripts}records_single.php`,
            get_contacts: `${url_scripts}contacts.php`,
            get_records: `${url_scripts}records_multiple.php`,
            set_media: `${url_scripts}create_mediaUploader.php`,
            set_text: `${url_scripts}create_textUploader.php`,
            options: `${url_scripts}records_options.php`,
            comments: `${url_scripts}comments.php`,
            statistics: `${url_scripts}statistics.php`,
        },
        zennolab: {
            zennolab_main,
            media_itemsZenno: `${zennolab_main}content/`,
            upload_filesZenno: `${zennolab_main}scripts/uploadFiles.php`,
            upload_textZenno: `${zennolab_main}scripts/uploadText.php`,
            elementsZenno: `${zennolab_main}scripts/elements.php`,
        },
    },
};

export default URL_COLLECTION;
