import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { store } from "./app/store";
import AppLayer from "./components/AppLayer";
import AuthProvider from "./features/auth/AuthProvider";
import Comments from "./features/comments/Comments";
import Main from "./features/main/Main";
import Messages from "./features/messages/Messages";
import CreateProvider from "./routes/Creator/CreateProvider";
import RecordsProvider from "./routes/Records/RecordsProvider";

export default function App() {
    return (
        <BrowserRouter>
            <ReduxProvider store={store}>
                <AuthProvider>
                    <AppLayer>
                        <Routes>
                            <Route path="/" element={<Main />} />
                            <Route path="/messages" element={<Messages />} />
                            <Route path="/comments" element={<Comments />} />

                            <Route path="/createnews" element={<CreateProvider />}>
                                <Route path=":edit_id" element={<CreateProvider />} />
                            </Route>
                            <Route path="/createadv" element={<CreateProvider />}>
                                <Route path=":edit_id" element={<CreateProvider />} />
                            </Route>

                            <Route path=":path/*" element={<RecordsProvider />}>
                                <Route path=":page/*" element={<RecordsProvider />} />
                            </Route>
                        </Routes>
                    </AppLayer>
                </AuthProvider>
            </ReduxProvider>
        </BrowserRouter>
    );
}
