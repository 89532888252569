import axios from "axios";
import $ from "jquery";
import URL_COLLECTION from "../../assets/data/URL";
import { ResponseGetAllSiteData, TypeGetAllSiteData, TypeGetStatistics } from "./main.interface";

export const fetchStatistics = async (type: TypeGetStatistics) => {
    const res = await axios.get(`${URL_COLLECTION.scripts.main.statistics}?type=${type}`).then((response) => {
        response.data.statisticsLoaded = true;
        return response;
    });
    return res.data;
};

export const fetchAllSiteData = async ({
    type = "get",
    data,
}: TypeGetAllSiteData | undefined = {}): Promise<ResponseGetAllSiteData> => {
    const paramsMainData = $.param({ ...data, site: data?.site ? `${data?.site}=${data?.site}` : "_" });

    return await axios
        .get(`${URL_COLLECTION.scripts.main.partitions}?type=${type}&${paramsMainData}`)
        .then((response) => response.data);
};
