import { FC } from "react";
import { useAppDispatch } from "../../app/hooks";
import style from "../../css/Pages.module.css";
import stringToColor from "../../utils/functions/stringToColor";
import { Message } from "./messages.interface";
import { requestMessages } from "./messagesSlice";

interface IMessageItem {
    messageData: Message;
}

const MessageItem: FC<IMessageItem> = ({ messageData }) => {
    const { id, name, email, message, timestamp } = messageData;
    const dispatch = useAppDispatch();

    return (
        <div data-id={id} className={style.message_container}>
            <div className={style.logo_user} style={{ backgroundColor: stringToColor(name) }}>
                {name[0]}
            </div>
            <div className={style.message_block}>
                <div className={style.name}>
                    {name}
                    <span>{timestamp}</span>
                    <span className={style.identificator}>#{id}</span>
                </div>
                <div className={style.message}>&nbsp;{message}</div>
                <div className={style.email}>
                    E-mail:&nbsp;
                    <a
                        style={{ color: stringToColor(name) }}
                        href={`mailto:${email}`}
                        target="_blank"
                        rel="noopener noreferer"
                    >
                        {email}
                    </a>
                </div>
            </div>
            <span
                className={style.delete}
                onClick={() => {
                    dispatch(requestMessages({ type: "deleteMessage", id }));
                }}
            ></span>
        </div>
    );
};
export default MessageItem;
