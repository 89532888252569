import { FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ILoginRequest } from "./auth.interface";
import styles from "./auth.module.scss";
import { userLogin } from "./authActions";
import { getUser } from "./authSlice";
import { isAdmin } from "./helpers/checkProfile";

interface IAuth {}

const LoginScreen: FC<IAuth> = ({}) => {
    const checkboxRef = useRef<HTMLInputElement | null>(null);

    const [isSave, setIsSave] = useState(false);

    const { loading, error, userInfo } = useAppSelector(getUser);
    const dispatch = useAppDispatch();
    const { register, handleSubmit } = useForm<ILoginRequest>();
    const navigate = useNavigate();

    const submitForm = (data: ILoginRequest) => {
        dispatch(userLogin({ ...data, isSave }));
    };
    const onClickCheckbox = () => setIsSave((prevstate) => !prevstate);

    useEffect(() => {
        if (isAdmin(userInfo)) navigate("/");
    }, [loading, navigate]);

    return (
        <div className={styles.fullEntryBlock}>
            <div className={styles.entry_block_area}>
                <div className={styles.entry_block}>
                    <form
                        className="login-form"
                        autoComplete="on"
                        noValidate
                        onSubmit={handleSubmit(submitForm)}
                    >
                        <h2>
                            Панель администратора <br />
                            сайта Clubok24.ru
                        </h2>
                        <div className={styles.entry_menu}>
                            <div className={styles.entry_login}>
                                <div className={styles.title}>Логин</div>
                                <input
                                    {...register("login")}
                                    required
                                    type="text"
                                    placeholder="my_login"
                                    className={styles.controlInput}
                                ></input>
                            </div>
                            <div className={styles.entry_password}>
                                <div className={styles.title}>Пароль</div>
                                <input
                                    {...register("password")}
                                    required
                                    type="password"
                                    placeholder="my_password"
                                    className={styles.controlInput}
                                ></input>
                            </div>

                            <div className={styles.entry_checkbox}>
                                <div id={styles.checkbox_title} className={styles.title}>
                                    Запомнить меня &rArr;
                                </div>
                                <input
                                    ref={checkboxRef}
                                    onChange={onClickCheckbox}
                                    checked={isSave}
                                    type="checkbox"
                                    id={styles.highload1}
                                    name="highload1"
                                ></input>
                                <label
                                    htmlFor={styles.highload1}
                                    data-onlabel="Да"
                                    data-offlabel="Нет"
                                    className={styles.lb1}
                                ></label>
                            </div>
                        </div>
                        <div className={styles.log_in}>
                            {error && (
                                <div id="error_login" className={styles.error_message}>
                                    Логин или пароль <br /> не верны
                                </div>
                            )}
                            <button type="submit" className={styles.loginbutton}>
                                {loading ? "Загрузка.." : "Войти"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className={`${styles.loadingEl} ${loading ? styles.active : ""}`}>
                <div className={styles.innerBrick}>
                    <div className={styles.tail}></div>
                </div>
                <div className={styles.innerBrick}>
                    <div className={styles.tail}></div>
                </div>
                <div className={styles.innerBrick}>
                    <div className={styles.tail}></div>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
