import { FC } from "react";
import { useAppDispatch } from "../../app/hooks";
import style from "../../css/Comments.module.css";
import { Comment } from "./comments.interface";
import { requestComments } from "./commentsSlice";

interface IComment {
    comment: Comment;
}

const CommentItem: FC<IComment> = ({ comment }) => {
    const dispatch = useAppDispatch();

    const {
        id,
        news_id,
        name_comment,
        message_comment,
        email,
        timestamp,
        likes,
        disslikes,
        link,
        part,
        site,
        parent_id,
    } = comment;

    const category = part ? part.split("=")[0].toLowerCase() : "";
    return (
        <div id={`comment_${id}`} className={style.comment_wrapper} data-id={id} data-news_id={news_id}>
            <div className={style.comment_area}>
                <div className={style.user_name_date}>
                    <div className={style.user_name}>{name_comment}</div>{" "}
                    <div className={style.id_comment}>№{id}</div>
                    <div className={style.date}>{timestamp}</div>
                    <div className={style.like_disslike}>
                        <div className={style.likes}>
                            Like: <span>{likes}</span>
                        </div>
                        <div className={style.disslikes}>
                            Diss: <span>{disslikes}</span>
                        </div>
                    </div>
                    <a href={"//" + site} target="_blank" className={style.site} title="Сайт записи">
                        {site}
                    </a>
                    {parent_id && (
                        <div>
                            Ответ на комментарий № <a href={`#comment_${parent_id}`}>{parent_id}</a>
                        </div>
                    )}
                </div>
                <div className={style.comment_content}>{message_comment}</div>
                <div className={style.user_email}>{email === "none" ? "E-mail отсутствует" : email}</div>

                <a
                    href={`https://${site}/category/${category}/${link}#${id}_idc_6_idp`}
                    target="_blank"
                    className={style.news_id}
                >
                    Запись № <span>{news_id}</span>
                </a>
            </div>
            <div
                onClick={() =>
                    dispatch(
                        requestComments({
                            type: "remove",
                            id,
                        })
                    )
                }
                className={style.remove}
                title="Удалить комментарий"
            >
                <span></span>
                <span></span>
            </div>
        </div>
    );
};

export default CommentItem;
