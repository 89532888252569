import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SITES } from "../../assets/data/DEFAULT_DATA";
import URL_COLLECTION from "../../assets/data/URL";
import EditorTextArea from "../../components/Input/EditorTextArea";
import InputText from "../../components/Input/InputText";
import TrippleCheckbox from "../../components/Input/TrippleCheckbox";
import UploadContainer from "../../components/Input/UploadContainer";
import Create_options from "../../components/Options/Create_options";
import Filter from "../../components/Sorting/Filter/Filter";
import style from "../../css/Pages.module.css";
import Keywords from "../../features/main/Keywords/Keywords";
import { Sites } from "../../features/main/main.interface";
import { changeSite, getAllSiteData } from "../../features/main/mainSlice";
import { useCreate } from "./CreateProvider";

const CreateNews = () => {
    const dispatch = useAppDispatch();
    let { pathname } = useLocation();
    pathname = pathname?.split("/")[1] || "home";
    const { edit_id } = useParams();
    const titlePage = edit_id ? `Редактирование записи №${edit_id}` : "Добавить новую запись";

    const { partitions } = useAppSelector(getAllSiteData);

    //@ts-ignore
    const { data, files, creator } = useCreate();
    const { onChangeData, onDropFiles, onDeleteMedia } = creator;
    const DP = data[pathname];

    return (
        <>
            <div className={style.title_opt}>{titlePage}</div>
            <div className={style.centralColumnWrapper}>
                <div className={style.oneItemPageBlock}>
                    <div className={style.content_oneitemPage}>
                        <InputText
                            name="titleNews"
                            value={DP["titleNews"]}
                            onChange={(event: any) =>
                                onChangeData({
                                    event: event,
                                    name: "titleNews",
                                    dublicateName: edit_id ? false : "link",
                                    type: edit_id ? false : "transliterated_string",
                                    pathname,
                                })
                            }
                            autofocus
                            emoji
                            isInsertButtons
                            required
                            max={900}
                            required_class="_req"
                            type="text"
                            input_insc="Заголовок новости"
                            input_plc="Введите заголовок новости.."
                        />
                        <InputText
                            name="link"
                            value={DP["link"]}
                            onChange={(event) =>
                                onChangeData({
                                    event: event,
                                    dublicateName: "link",
                                    type: "transliterated_string",
                                    pathname,
                                })
                            }
                            required
                            max={900}
                            required_class="_req"
                            type="text"
                            input_insc="Ссылка на запись (генерируется автоматически)"
                            input_plc="example-1"
                            disabled={edit_id ? true : false}
                            styleInput={{
                                backgroundColor: edit_id ? "#eee" : "inherit",
                            }}
                        />
                        <div className={style.row_selectors}>
                            <Filter
                                prefix=""
                                head="Целевой сайт"
                                title="Выбрать сайт записи"
                                options={SITES}
                                selectedOption={DP["site"]}
                                onClick={(value) => {
                                    dispatch(changeSite(value as Sites));
                                    onChangeData({ name: "site", value, pathname });
                                }}
                            />
                            <Filter
                                prefix=""
                                head="Раздел"
                                title="Выбрать раздел записи"
                                options={partitions}
                                selectedOption={DP["partition"]}
                                selectedNestedOption={DP["subpartition"]}
                                isNestedFilter
                                onClick={(value) => {
                                    onChangeData({
                                        name: "partition",
                                        dublicateName: "subpartition",
                                        clearDublicate: true,
                                        value,
                                        pathname,
                                    });
                                }}
                                onClickSubpartition={(value, parent_value) => {
                                    onChangeData({
                                        name: "partition",
                                        value: parent_value,
                                        dublicateName: "subpartition",
                                        dublicateValue: value,
                                        pathname,
                                    });
                                }}
                            />
                            <TrippleCheckbox
                                name="isVideoFrame"
                                id="isVideoFrame"
                                value={DP["isVideoFrame"]}
                                onChange={(event) => onChangeData({ name: "isVideoFrame", event, pathname })}
                                options={["Нет", "Видео", "Текст"]}
                                insc="Рамка обложки"
                            />
                        </div>
                        <InputText
                            name="view"
                            value={DP["view"]}
                            onChange={(event: any) => onChangeData({ event, name: "view", pathname })}
                            max={30}
                            type="number"
                            input_insc="Просмотры"
                            input_plc="Введите количество просмотров"
                            style={{
                                width: "fit-content",
                            }}
                            styleInput={{
                                width: "150px",
                            }}
                            tag="input"
                        />
                        <UploadContainer
                            name="media"
                            ins="Главная обложка (изображение/видео)"
                            className="_req media"
                            files={files}
                            url={URL_COLLECTION.media_items}
                            onChange={(value: any) =>
                                onChangeData({ value, name: "mediaReplace", pathname: "currentItem" })
                            }
                            onDelete={(event: any) => onDeleteMedia({ event, pathname })}
                            onInput={(input: any) => onDropFiles({ acceptedFiles: input, pathname })}
                        />
                        <a id="top_editor_admincl24">
                            <i aria-hidden={true}></i>
                        </a>
                        <EditorTextArea
                            name="descNews"
                            value={DP["descNews"]}
                            onChange={(value: any) => onChangeData({ value, name: "descNews", pathname })}
                            max={16777215}
                            required_class="_req"
                            ins="Тело новости"
                            placeholder="Введите описание статьи..."
                        />
                        <a id="bottom_editor_admincl24">
                            <i aria-hidden={true}></i>
                        </a>
                        <div
                            className={style.title_opt_metaData}
                            title="Благодаря этим данным, новости будут чаще попадать в поисковики"
                        >
                            Meta-данные
                        </div>
                        <div className={style.table_meta_data}>
                            <InputText
                                name="keywords"
                                value={DP["keywords"]}
                                onChange={(event: any) => onChangeData({ event, name: "keywords", pathname })}
                                max={255}
                                type="text"
                                autocomplete="off"
                                input_insc="Ключевые слова (через пробел)"
                                input_plc="Введите ключевые слова.."
                            />
                            <Keywords
                                onClick={(value: any) =>
                                    onChangeData({
                                        value: value + " ",
                                        name: "keywords",
                                        pathname,
                                        replace: true,
                                    })
                                }
                                searchdata={DP["keywords"]}
                            />
                            <InputText
                                name="desc"
                                value={DP["desc"]}
                                onChange={(event: any) => onChangeData({ event, name: "desc", pathname })}
                                max={1000}
                                type="text"
                                input_insc="Описание страницы"
                                input_plc="Введите описание страницы.."
                            />
                            <div className={style.meta_data}>
                                <div className={style.input_date}>
                                    <InputText
                                        name="date"
                                        value={DP["date"]}
                                        onChange={(event: any) =>
                                            onChangeData({ event, name: "date", pathname })
                                        }
                                        type="date"
                                        input_insc="Дата:"
                                        input_plc="12.01.1901"
                                        tag="input"
                                    />
                                </div>
                                <div className={style.input_time}>
                                    <InputText
                                        name="datetime"
                                        value={DP["datetime"]}
                                        onChange={(event: any) =>
                                            onChangeData({ event, name: "datetime", pathname })
                                        }
                                        type="time"
                                        input_insc="Время:"
                                        input_plc="00:00"
                                        tag="input"
                                    />
                                </div>
                            </div>
                        </div>
                        <Create_options />
                    </div>
                </div>
            </div>
        </>
    );
};
export default React.memo(CreateNews);
