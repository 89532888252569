import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, StatusReducer } from "../../app/types";
import { fetchMessages } from "./messages.api";
import { IRequestMessages, Message } from "./messages.interface";

export interface IInitialStateMessages {
    messages: Message[];
    status: StatusReducer;
}

const initialState: IInitialStateMessages = {
    messages: [],
    status: "idle",
};

export const requestMessages = createAsyncThunk(
    "messages/requestMessages",
    async (args: IRequestMessages) => {
        return await fetchMessages(args);
    }
);

export const messagesSlice = createSlice({
    name: "messages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //handle all comments
            .addCase(requestMessages.pending, (state) => {
                state.status = "loading";
            })
            .addCase(requestMessages.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(requestMessages.fulfilled, (state, action) => {
                state.status = "idle";
                state.messages = action.payload;
            });
    },
});

export const selectMessages = (state: RootState) => state.messages;

export default messagesSlice.reducer;
