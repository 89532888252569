import axios from "axios";
import URL_COLLECTION from "../../assets/data/URL";
import { IRequestMessages, Message } from "./messages.interface";

const url_get_contacts = URL_COLLECTION.scripts.main.get_contacts;

export const fetchMessages = async ({ type, id }: IRequestMessages): Promise<Message[]> => {
    const isId = id ? `&id=${id}` : "";

    try {
        const response = await axios.get(`${url_get_contacts}?type=${type}${isId}`, {
            headers: {
                "Content-Type": "text/html",
            },
        });

        return Object.values(response.data || {});
    } catch (e) {
        console.error(e);
        return [];
    }
};
