import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { getUser } from "../../features/auth/authSlice";
import Searcher from "../Searcher";
import Links from "./Links";
import style from "./Menu.module.scss";
import NavLinks from "./NavLinks";

const Menu = ({ width = 639, maxWidth }: { width?: number; maxWidth: number }) => {
    const { userInfo, userToken } = useAppSelector(getUser);

    const [view, setView] = useState(false);
    useEffect(() => {
        if (width > maxWidth) setView(true);
    }, [width]);

    return (
        <div className={style.mainNavigationsBar}>
            <div className={style.mainNavRow}>
                <div onClick={() => setView(!view)} className={style.viewPartitionsBar_button}></div>
                <div className={style.titleSite}>
                    <div className={style.sitename}>Админ панель</div>
                    {"name" in userInfo && (
                        <div className={style.greetings}>
                            Здравствуйте, <span>{userInfo.name}</span>!
                        </div>
                    )}
                </div>
            </div>
            {view && (
                <nav className={style.partitionsBar}>
                    <Searcher />
                    <NavLinks />
                    <Links />
                </nav>
            )}
        </div>
    );
};
export default Menu;
