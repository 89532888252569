import { Middleware } from "@reduxjs/toolkit";
import { AppDispatch } from "../../app/types";
import { changeSite, requestSiteData } from "./mainSlice";

export const siteDataMiddleware: Middleware =
    ({ dispatch }: { dispatch: AppDispatch }) =>
    (next) =>
    //TODO: типизировать ету шляпу
    (action: any) => {
        if (action.type === changeSite.type) {
            dispatch(
                requestSiteData({
                    data: {
                        site: action.payload,
                    },
                })
            );
        }
        return next(action);
    };
