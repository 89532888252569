import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import style from "../../css/Comments.module.css";
import stylePages from "../../css/Pages.module.css";
import CommentItem from "./Comment";
import { requestComments, selectComments } from "./commentsSlice";

export default function Comments() {
    const comments = useAppSelector(selectComments);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(requestComments({}));
    }, []);

    const memoComments = useMemo(() => {
        return comments?.map((comment, index) => <CommentItem comment={comment} key={index} />);
    }, [comments]);

    return (
        <>
            <div className={stylePages.title_opt}>Комменнтарии пользователей</div>
            <div className={style.comments_wrapper}>{memoComments}</div>
        </>
    );
}
