import { PropsWithChildren } from "react";
import style from "../css/Pages.module.css";
import Menu from "./Menu/Menu";
import Popup from "./Popup";
import { useResizeDetector } from "react-resize-detector";

interface IAppLayer {}

const AppLayer = ({ children }: PropsWithChildren<IAppLayer>) => {
    const { ref, width } = useResizeDetector();
    const maxWidth = 640;

    return (
        <>
            <div className={style.reload_page}></div>
            <div id="content_clubok24" ref={ref}>
                <Menu width={width} maxWidth={maxWidth} />
                <div className={style.mainWrapper}>
                    <div className={style.leftColumnWrapper}></div>
                    <div className={style.centralColumn}>{children}</div>
                    <div className={style.rightColumnWrapper}></div>
                </div>
            </div>
            <Popup />
        </>
    );
};

export default AppLayer;
