import { useState } from "react";
import { SITES } from "../../assets/data/DEFAULT_DATA";
import { useRecords } from "../../routes/Records/RecordsProvider";
import Selector from "../Input/Selector";
import style from "./Options.module.css";

export default function Item_FormCopy({ id, site, setViewForm }) {
    const { methods } = useRecords();
    const { options_item } = methods;

    const possible_sites = structuredClone(SITES);
    const current_index = possible_sites.findIndex((element) => element.includes(`=${site}`));
    possible_sites.splice(current_index, 1);

    const [value, setValue] = useState(possible_sites[0]?.split("=")[0] || "");

    function onSendFormCopy() {
        const copy = {
            id,
            prevsite: site,
            nextsite: value?.split("=")[0],
        };
        options_item({ action: "copy", copy });
        setViewForm(false);
    }

    return (
        <div className={style.form_copy}>
            <div className={style.container}>
                <div className={style.header}>Копировать запись на другой сайт:</div>
                <div className={style.body}>
                    <Selector
                        name="copy"
                        value={value}
                        title={false}
                        onChange={(event) => setValue(event.target.value)}
                        options={possible_sites}
                        className={style.selector}
                    />
                    <button onClick={onSendFormCopy} className={style.send}>
                        Копировать
                    </button>
                </div>
            </div>
        </div>
    );
}
