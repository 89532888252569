import style from "../../css/Pages.module.css";

export function message(m = "Выполнено успешно.", s = "OK", t = "Статус:", e = 0) {
    const elemPopup = document.getElementById("popup_adcl24");
    const titlePopup = document.getElementById("title_adcl24");
    const statusPopup = document.getElementById("status_adcl24");
    const messagePopup = document.getElementById("message_adcl24");
    if (!elemPopup || !messagePopup || !titlePopup || !statusPopup) return;

    const hide = () => elemPopup.classList.remove(style.popup_visible);
    const show = () => {
        elemPopup.classList.add(style.popup_visible);
        setTimeout(() => hide(), 5000);
    };
    const setContent = () => {
        messagePopup.innerHTML = m;
        titlePopup.innerHTML = t;
        statusPopup.innerHTML = s;
        elemPopup.setAttribute("data-error", e.toString());
    };

    hide();
    setTimeout(() => {
        setContent();
        show();
    }, 500);
}

export function reload(isReload: boolean = false) {
    if (isReload) blackout(style.reload_page, 0, 1, "visible");
    else blackout(style.reload_page, 0, 0, "hidden");
}

export function blackout(style: string, elem: number, opacity: number, visibility: string) {
    const darkScreenFilter = document.getElementsByClassName(style)[elem] as HTMLElement;
    if (!darkScreenFilter) return null;

    darkScreenFilter.style.opacity = opacity.toString();
    darkScreenFilter.style.visibility = visibility;
}
