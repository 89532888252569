import { FC } from "react";
import { useAppDispatch } from "../../app/hooks";
import URL_COLLECTION from "../../assets/data/URL";
import { logout } from "../../features/auth/authSlice";
import style from "./Menu.module.scss";

interface ILinks {}
const { main, video, news } = URL_COLLECTION.sites;

const Links: FC<ILinks> = () => {
    const dispatch = useAppDispatch();

    return (
        <>
            <a
                href="https://metrika.yandex.ru/dashboard?id=91532407"
                target="_blank"
                rel="noopener noreferrer"
                id={style.site_yam}
                className={style.navigationTarget}
            >
                Яндекс.Метрика
            </a>
            <a
                href="https://webmaster.yandex.ru/site/https:clubok24.ru:443/dashboard/"
                target="_blank"
                rel="noopener noreferrer"
                id={style.site_yawm}
                className={style.navigationTarget}
            >
                Яндекс.Вебмастер
            </a>
            <a
                href="https://cp.sweb.ru/hosting/file_manager"
                target="_blank"
                rel="noopener noreferrer"
                id={style.site_sweb}
                className={style.navigationTarget}
            >
                Хостинг SWEB
            </a>
            <a
                href="https://cpa.tl/"
                target="_blank"
                rel="noopener noreferrer"
                id={style.site_tl}
                className={style.navigationTarget}
            >
                Trafic Light
            </a>
            <a
                href={main}
                target="_blank"
                rel="noopener noreferrer"
                id={style.site}
                className={style.navigationTarget}
            >
                CLUBOK24.RU
            </a>
            <a
                href={video}
                target="_blank"
                rel="noopener noreferrer"
                id={style.site}
                className={style.navigationTarget}
            >
                VIDEO.CLUBOK24.RU
            </a>
            <a
                href={news}
                target="_blank"
                rel="noopener noreferrer"
                id={style.site}
                className={style.navigationTarget}
            >
                SUPER-STORY.RU
            </a>
            <a
                href={URL_COLLECTION.url_scripts + `phpinfo.php`}
                target="_blank"
                rel="noopener noreferrer"
                id={style.php}
                className={style.navigationTarget}
            >
                PHP INFO()
            </a>
            <div
                className={style.navigationTarget}
                id={style.logout}
                onClick={() => dispatch(logout())}
                title="Выйти из аккаунта"
            >
                Выйти из аккаунта
            </div>
        </>
    );
};

export default Links;
