import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SITES } from "../../assets/data/DEFAULT_DATA";
import Selector from "../../components/Input/Selector";
import stylesCreate from "../../css/Create.module.scss";
import style from "../../css/Pages.module.css";
import Keywords from "./Keywords/Keywords";
import { Sites } from "./main.interface";
import { changeSite, getAllSiteData, getCurrentSite, requestSiteData, requestStatistics } from "./mainSlice";
import PartitionsWrapper from "./Partitions/PartitionsWrapper";
import Statistics from "./Statistics/Statistics";

const Main = () => {
    const currentSite = useAppSelector(getCurrentSite);
    const { ads_sections, status, partitions } = useAppSelector(getAllSiteData);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(requestStatistics("all"));
        dispatch(requestSiteData({ data: { site: currentSite }, type: "get" }));
    }, []);

    return (
        <>
            <div className={style.title_opt}>Главная страница</div>
            <div className={style.content_oneitemPage}>
                <form target="dummyframe" onReset={() => {}}>
                    <Statistics />
                    <div className={` ${stylesCreate.title_global_settings}`}>Настройки сайта</div>
                    <div className={stylesCreate.global_settings}>
                        <Keywords isActions={false} />
                        <div className={stylesCreate.target_site_row}>
                            <Selector
                                title="Целевой сайт"
                                value={`${currentSite}=${currentSite}`}
                                onChange={(e) => dispatch(changeSite(e.currentTarget.value.split("=")[0] as Sites))}
                                options={SITES || []}
                            />
                        </div>
                        {status === "idle" ? (
                            <div className={stylesCreate.main_partitions}>
                                <PartitionsWrapper
                                    data={partitions}
                                    title="2. Рубрики сайта"
                                    titleInput="Добавить рубрику сайта"
                                    table="partitions"
                                />
                                <PartitionsWrapper
                                    data={ads_sections}
                                    title="3. Разделы рекламы"
                                    titleInput="Добавить раздел сайта"
                                    table="adv_sections"
                                    isSubpartitions={false}
                                />
                            </div>
                        ) : (
                            <div className={stylesCreate.loading_main} />
                        )}
                    </div>
                </form>
                <iframe title="#" name="dummyframe" id="dummyframe" style={{ display: "none" }}></iframe>
            </div>
        </>
    );
};
export default Main;
