import { Dispatch, FocusEvent, SetStateAction, SyntheticEvent, useState } from "react";
import { removeItemOnce } from "../../../../utils/functions/arrays";
import transliterate from "../../../../utils/functions/transliterator";
import { validatePartition } from "../partitions.helpers";
import type { TParamsPartitions, TPartition, TSubpartition } from "../partitions.interface";
import styles from "../Partitions.module.scss";
import { onChangeSubpartFeatures, SubpartFeatures } from "../PartitionsWrapper";
import SubPartitions from "./SubPartitions";

interface IPartition {
    partition: TPartition | TSubpartition;
    paramsPartitions: TParamsPartitions;
    className?: string;

    isEdit: boolean;
    isRestore: boolean;
    subpartitions_params?: {
        isActive: boolean;
        subpartFeatures: SubpartFeatures;
        onChangeSubpartFeatures: (features: onChangeSubpartFeatures) => void;
    };

    setParamsPartitions: Dispatch<SetStateAction<TParamsPartitions>>;
}

const deletedClassName = styles.pe_deleted_partition;

export default function Partition({
    partition,
    paramsPartitions,
    className,

    isEdit,
    isRestore,
    subpartitions_params,
    setParamsPartitions,
}: IPartition) {
    const [error, setError] = useState<string | boolean>(false);

    const { active, id, length, name, timestamp } = partition;

    function onInputPartition(e: SyntheticEvent<HTMLDivElement>) {
        const dataname = e.currentTarget.getAttribute("data-name");

        if (!dataname) return;
        const current = e.currentTarget.textContent;
        const currentTrancliterated = `${transliterate(current)}=${current}`;

        const isArrayPrev = Array.isArray(paramsPartitions?.prevent);
        const indexPrev = isArrayPrev ? paramsPartitions?.prevent.indexOf(dataname) : 0;

        if (!isArrayPrev) {
            setParamsPartitions((prevstate) => ({
                ...prevstate,

                prevent: [dataname],
                current: [currentTrancliterated],
            }));
        } else {
            if (indexPrev != -1) {
                const newArrayPrev = [...paramsPartitions?.prevent];
                const newArrayCurrent = [...paramsPartitions?.current];
                newArrayPrev[indexPrev] = dataname;
                newArrayCurrent[indexPrev] = currentTrancliterated;

                setParamsPartitions((prevstate) => ({
                    ...prevstate,
                    prevent: newArrayPrev,
                    current: newArrayCurrent,
                }));
            } else {
                setParamsPartitions((prevstate) => ({
                    ...prevstate,
                    type: "set",
                    prevent: [...prevstate?.prevent, dataname],
                    current: [...prevstate?.current, currentTrancliterated],
                }));
            }
        }
    }
    function onClickRecycle(e: SyntheticEvent<HTMLDivElement>) {
        const dataname = e.currentTarget.getAttribute("data-name");
        if (!dataname || dataname === "Novosti=Новости") return;

        const isArray = Array.isArray(paramsPartitions?.recycle);
        const isNotDefine = isArray && !paramsPartitions?.recycle?.includes(dataname);

        if (!isArray) setParamsPartitions((prevstate) => ({ ...prevstate, recycle: [] }));

        if (!isArray || isNotDefine) {
            setParamsPartitions((prevstate) => ({
                ...prevstate,
                recycle: [...prevstate?.recycle, dataname],
            }));
            e.currentTarget.classList.add(deletedClassName);
        } else {
            setParamsPartitions((prevstate) => ({
                ...prevstate,
                recycle: removeItemOnce([...prevstate?.recycle], dataname),
            }));
            e.currentTarget.classList.remove(deletedClassName);
        }
    }

    const onBlur = (e: FocusEvent<HTMLDivElement, Element>) => {
        if (!isEdit || e.currentTarget.contains(e.relatedTarget as Node)) return;
        const isvalid = validatePartition(e.target.textContent);
        if (typeof isvalid === "string") setError(isvalid);
        else setError(false);
    };

    return (
        <div className={styles.pe_wrapper}>
            {subpartitions_params?.isActive && (
                <SubPartitions
                    parent_id={id}
                    subpartitions={"subpartitions" in partition && partition.subpartitions}
                    subpartFeatures={subpartitions_params.subpartFeatures}
                    onChangeSubpartFeatures={subpartitions_params.onChangeSubpartFeatures}
                />
            )}
            {error && <div className={styles.error}>{error}</div>}
            <div
                data-error={!!error}
                tabIndex={1}
                data-name={name}
                data-count={length}
                data-active={active}
                data-index={id}
                data-errow
                data-editable={isEdit}
                contentEditable={isEdit}
                suppressContentEditableWarning={true}
                className={`el_pn ${className ? className : ""} ${styles.partition_elem} ${
                    isEdit || isRestore ? styles.partition_elem_editable : ""
                }`}
                onClick={(e) => isRestore && onClickRecycle(e)}
                onInput={(e) => isEdit && onInputPartition(e)}
                onBlur={onBlur}
            >
                {name.split("=")[1]}
            </div>
            <span title="Количество связанных записей" contentEditable={false} className={styles.pne_length}>
                {length}
            </span>
        </div>
    );
}
