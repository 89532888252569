import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import URL_COLLECTION from "../../assets/data/URL";
import { changeSite } from "../../features/main/mainSlice";
import { detectMime } from "../../utils/functions/media";
import CreateAdv from "./CreateAdv";
import CreateNews from "./CreateNews";
import { useCreate } from "./CreateProvider";

function Paths() {
    const dispatch = useAppDispatch();
    let { pathname } = useLocation();
    pathname = pathname?.split("/")[1] || "home";

    const { creator } = useCreate();
    const { onChangeData, onDropFiles } = creator;

    const { edit_id } = useParams();

    function build_files({ id, arrayMd5 }) {
        const files_edit = [];

        for (let i = 0; i < arrayMd5.length; i++) {
            if (arrayMd5[i] === "") continue;
            const ext = arrayMd5[i].split(".")[1];

            files_edit.push({
                name: arrayMd5[i],
                preview: `${URL_COLLECTION.media_items}${arrayMd5[i]}`,
                type: `${detectMime(ext)}/${ext}`,
                item_id: id,
            });
        }

        return files_edit;
    }
    //(MACROSS) creating an entry when going to the editor
    function build_news(response) {
        if (!response || !response?.data || !Object.values(response.data)?.length) return;
        const {
            title,
            desc_news,
            time_local,
            keywords,
            date_local,
            desc_meta,
            part,
            isVideoFrame,
            media,
            link,
            view,
            id,
            rating,
            site,
        } = Object.values(response.data)[0];

        onChangeData({ value: title, name: "titleNews", pathname });
        onChangeData({ value: desc_news, name: "descNews", pathname });
        onChangeData({ value: date_local, name: "date", pathname });
        onChangeData({ value: time_local, name: "datetime", pathname });
        onChangeData({ value: keywords, name: "keywords", pathname });
        onChangeData({ value: desc_meta, name: "desc", pathname });
        onChangeData({ value: part, name: "partition", pathname });
        onChangeData({ value: view, name: "view", pathname });
        onChangeData({ value: link, name: "link", pathname });
        onChangeData({ value: id, name: "id", pathname });
        onChangeData({ value: parseInt(isVideoFrame, 10), name: "isVideoFrame", pathname });
        onChangeData({ value: media, name: "md5", pathname });
        onChangeData({ value: [...Object.values(rating)], name: "rating", pathname });
        onChangeData({ value: `${site}=${site}`, name: "site", pathname });

        onChangeData({ value: id, name: "id", pathname: "currentItem" });

        dispatch(changeSite(site));

        const arrayMd5 = media?.split(",");

        onDropFiles({
            addToData: false,
            acceptedFiles: build_files({ id, arrayMd5 }),
            pathname,
        });
    }
    function build_adv(response) {
        if (!response || !response?.data || !Object.values(response.data)?.length) return;
        const {
            id,
            title,
            link,
            media,
            position,
            timestamp,
            view,
            footnote,
            insc_ads,
            partition,
            is_trash,
            note,
        } = Object.values(response.data)[0];

        onChangeData({ value: title, name: "titleAdv", pathname });
        onChangeData({ value: link, name: "linkAdv", pathname });
        onChangeData({ value: timestamp, name: "timestamp", pathname });
        onChangeData({ value: footnote, name: "footnote", pathname });
        onChangeData({ value: note, name: "note", pathname });
        onChangeData({ value: parseInt(is_trash, 10), name: "is_trash", pathname });
        onChangeData({ value: partition, name: "partition", pathname });
        onChangeData({ value: view, name: "view", pathname });
        onChangeData({ value: parseInt(insc_ads, 10), name: "insc_ads", pathname });
        onChangeData({ value: media, name: "md5", pathname });
        position &&
            typeof position === "string" &&
            onChangeData({ value: position.split(","), name: "position", pathname });

        onChangeData({ value: id, name: "id", pathname: "currentItem" });

        const arrayMd5 = media.split(",");

        onDropFiles({
            addToData: false,
            acceptedFiles: build_files({ id, arrayMd5 }),
            pathname,
        });
    }

    let target_build_script = () => {},
        target_table = false,
        Target_JSX = <></>;
    switch (pathname) {
        case "createnews":
            target_build_script = build_news;
            target_table = "news";
            Target_JSX = <CreateNews />;
            break;
        case "createadv":
            target_build_script = build_adv;
            target_table = "adv";
            Target_JSX = <CreateAdv />;
            break;
        default:
/*             target_table = false;
            Target_JSX = <Main />; */
            break;
    }

    useEffect(() => {
        if (!target_table) return;
        axios
            .get(`${URL_COLLECTION.scripts.main.get_item_editview}?table=${target_table}&id=${edit_id}`)
            .then(target_build_script)
            .catch((msg) => console.error(msg))
            .finally(() => {
                target_build_script = () => {};
                target_table = false;
                Target_JSX = <></>;
            });
    }, [edit_id]);

    return Target_JSX;
}
export default React.memo(Paths);
