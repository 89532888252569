import { Link, useLocation } from "react-router-dom";
import { TypeKeyword } from "./Keywords.interface";
import style from "./Keywords.module.scss";

interface IKeyword {
    data: TypeKeyword;
    searchdata?: string;
    className?: string;
    isLink?: boolean;
    onClick?: (text: string) => void;
}

export const Keyword = ({ data, searchdata = "", isLink, className, onClick }: IKeyword) => {
    const { keyword = false, count = 0 } = data;

    let { pathname } = useLocation();
    pathname = pathname?.split("/")[1] || "home";
    
    if (!keyword) return <></>;

    function onClickSearchValues(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        const arrParams = searchdata?.split(" ");
        const lastIndex = arrParams?.length - 1;
        const lastElement = arrParams[lastIndex]?.trim();

        if (!lastElement) return typeof onClick === "function" && onClick(e.currentTarget.innerText);

        arrParams.splice(lastIndex, 1);
        arrParams.push(e.currentTarget.innerText);

        typeof onClick === "function" && onClick(arrParams.join(" "));
    }

    return (
        <div className={style.keyword_container}>
            <Link
                title={isLink ? `Перейти к записям по тегу "${keyword}"` : `Вставить "${keyword}"`}
                to={`/news?search=${keyword}`}
                onClick={(e) => {
                    if (isLink) return;
                    onClickSearchValues(e);
                }}
                //@ts-ignore
                name="keywords"
                data-path={pathname}
                className={`${className} ${style.keywords_element}`}
            >
                {keyword}
            </Link>
            <span title="Количество записей по тегу">{count}</span>
        </div>
    );
};

export default Keyword;
