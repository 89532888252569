import style from "./Statistics.module.css";

interface IStatisticItem {
    value?: string;
    heading?: string;
    title?: string;
}

export default function StatisticsItem({ title, heading = "", value = "" }: IStatisticItem) {
    return (
        <div className={style.statistics_item} title={title}>
            <div className={style.heading}>{heading}</div>
            <div className={style.value}>{value}</div>
        </div>
    );
}
