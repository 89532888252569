import { TypeKeyword } from "./Keywords/Keywords.interface";
import { IInitialStateMainSlice } from "./main.interface";
import { TPartition } from "./Partitions/partitions.interface";
import { Statistics } from "./Statistics/Statistics.interface";

export const DEFAULT_STATISTICS: Statistics = {
    commentsCount: "",
    createdAdvCount: "",
    createdNewsCount: "",
    ratingCount: "",
    viewsCount: "",
    not_viewed_comments: "",
    not_viewed_messages: "",
};

export const DEFAULT_KEYWORDS: TypeKeyword[] = [];

export const DEFAULT_ADS_SECTIONS: TPartition[] = [];

export const DEFAULT_PARTITIONS: TPartition[] = [];

export const InitialStateMain: IInitialStateMainSlice = {
    currentSite: "clubok24.ru",
    statistics: { status: "idle", data: DEFAULT_STATISTICS },
    allSiteData: {
        ads_sections: DEFAULT_ADS_SECTIONS,
        keywords: DEFAULT_KEYWORDS,
        partitions: DEFAULT_PARTITIONS,
        status: "idle",
    },
};
