import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import { authApi } from "../features/auth/auth.service";
import authReducer from "../features/auth/authSlice";
import commentsReducer from "../features/comments/commentsSlice";
import { siteDataMiddleware } from "../features/main/main.middleware";
import mainReducer from "../features/main/mainSlice";
import messagesReducer from "../features/messages/messagesSlice";

export const rootReducer = combineReducers({
    comments: commentsReducer,
    messages: messagesReducer,
    main: mainReducer,
    user: authReducer,
});

const middlewares = [siteDataMiddleware, authApi.middleware] as Middleware[];

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat(...middlewares),
});
