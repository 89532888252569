import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { getAllSiteData } from "../../features/main/mainSlice";
import style from "./Menu.module.scss";

interface INavLinks {}

const activeStyle = `${style.navigationTarget} ${style.navigationTarget_active}`;
const inactiveStyle = style.navigationTarget;
const className = ({ isActive }: { isActive: boolean }) => (isActive ? activeStyle : inactiveStyle);

const NavLinks: FC<INavLinks> = () => {
    const { not_viewed_comments, not_viewed_messages } = useAppSelector(getAllSiteData);

    return (
        <>
            <NavLink data-action="edit" to="/" id={style.main} className={className}>
                Главная
            </NavLink>
            <NavLink data-action="edit" to="news" id={style.news} className={className}>
                Новости
            </NavLink>
            <NavLink data-action="edit" to="createnews" className={className}>
                <span className={style.prefix}></span>Добавить новость
            </NavLink>
            <NavLink data-action="edit" to="comments" className={className}>
                <span className={style.prefix}></span>
                Комментарии
                {not_viewed_comments && not_viewed_comments !== "0" && (
                    <span className={style.count}>+{not_viewed_comments}</span>
                )}
            </NavLink>
            <NavLink data-action="edit" to="adv" id={style.amalker} className={className}>
                Реклама
            </NavLink>
            <NavLink data-action="edit" to="createadv" className={className}>
                <span className={style.prefix}></span>Добавить рекламу
            </NavLink>
            <NavLink data-action="edit" to="recycle" id={style.recycle} className={className}>
                Удалённые записи
            </NavLink>
            <NavLink data-action="edit" to="messages" id={style.managing} className={className}>
                Сообщения
                {not_viewed_messages && not_viewed_messages !== "0" && (
                    <span className={style.count}>+{not_viewed_messages}</span>
                )}
            </NavLink>
        </>
    );
};

export default NavLinks;
