import { ReactNode } from "react";
import { useAppSelector } from "../../app/hooks";
import { getUser } from "./authSlice";
import { isAdmin } from "./helpers/checkProfile";
import LoginScreen from "./LoginScreen";

interface IAuthProvider {
    children: ReactNode[] | ReactNode;
}

const AuthProvider = ({ children }: IAuthProvider) => {
    const { userInfo, userToken } = useAppSelector(getUser);

    if (!!userToken && isAdmin(userInfo)) return <>{children}</>;
    else {
        if (window.location.pathname !== "/") window.location.replace("/");
        return <LoginScreen />;
    }
};

export default AuthProvider;
