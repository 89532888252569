import { useState } from "react";
import stylesSorting from "../Sorting.module.css";
import styles from "./Filter.module.scss";
import Option from "./Option";
import { TSubpartition, TPartition, PartitionName } from "../../../features/main/Partitions/partitions.interface";

interface IFilter {
    title: string;
    options: TSubpartition[] | TPartition[] | PartitionName[] | string[];
    selectedOption: string;
    selectedNestedOption?: string;
    isStaticOption?: string;
    prefix?: string;
    allOption?: string;
    head?: string;
    isNestedFilter?: boolean;
    className?: string;
    onClick?: (value: string) => void;
    onClickSubpartition?: (value: string, parent_name: string) => void;
    onClickSelectedOption?: (value: string) => void;
}

export default function Filter({
    options,
    selectedOption,
    selectedNestedOption,
    isStaticOption,
    isNestedFilter,
    prefix,
    title,
    allOption,
    head,
    className,
    onClick,
    onClickSubpartition,
    onClickSelectedOption,
}: IFilter) {
    const [isVisibleOptionsList, setIsVisibleOptionsList] = useState(false);

    if (!options.length) return <></>;
    const newOptions = [...options];
    if (allOption) newOptions.unshift(`all=${allOption}`);

    const isEqualsWithEqualsSymbol = (string?: string) => {
        if (!selectedOption) return;

        string = string || selectedOption;
        if (!selectedOption.includes("=")) string = string.split("=")[0];
        return string.toLowerCase() === selectedOption.toLowerCase();
    };
    const findMatchingElement = () => {
        if (!selectedOption || !newOptions.length) return null;

        const foundElement = newOptions.find((element) => {
            if (!element || !selectedOption) return false;
            let targetValue;

            if (typeof element !== "string") {
                if (isNestedFilter && "subpartitions" in element) {
                    const subarray = Object.values(element.subpartitions).find((val) => {
                        return val.name && isEqualsWithEqualsSymbol(val.name);
                    });

                    if (subarray) targetValue = subarray.name;
                    else targetValue = element.name;
                } else targetValue = element.name;
            } else targetValue = element;

            return targetValue && isEqualsWithEqualsSymbol(targetValue);
        });

        return foundElement;
    };
    const CurrentOption = () => {
        if (isStaticOption) {
            return (
                <Option
                    value={selectedOption}
                    title={isStaticOption}
                    arrow={{
                        onClick: (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsVisibleOptionsList((prevstate) => !prevstate);
                        },
                    }}
                />
            );
        }
        const currentElement = findMatchingElement();
        if (!currentElement) return <></>;

        let titleOption = prefix ? prefix : "",
            engRusName,
            targetNestedOption;

        if (selectedNestedOption)
            //only nested options
            engRusName = selectedNestedOption.split("=");
        else {
            //only parents
            if (isNestedFilter && typeof currentElement !== "string" && "subpartitions" in currentElement) {
                //if parent has childs
                targetNestedOption = Object.values(currentElement.subpartitions).find(
                    (val) => val.name && isEqualsWithEqualsSymbol(val.name)
                );
                if (!targetNestedOption) engRusName = currentElement.name.split("=");
                else engRusName = targetNestedOption.name.split("=");
            } else {
                // if parent hasn`t childs
                engRusName =
                    typeof currentElement === "string"
                        ? currentElement.split("=")
                        : currentElement.name.split("=");
            }
        }

        const titleCond = selectedNestedOption && typeof currentElement !== "string";
        if (titleCond) titleOption += currentElement.name.split("=")[1];
        else if (targetNestedOption) titleOption += targetNestedOption.parent_name.split("=")[1];

        titleOption += (titleCond || targetNestedOption) && engRusName[1] ? ". " : "";
        titleOption += engRusName[1] ? engRusName[1] : "";

        titleOption = allOption && selectedOption === "all" ? allOption : titleOption;

        return <Option value={selectedOption} title={titleOption} />;
    };
    return (
        <div className={`${styles.select_container} ${className || ""}`}>
            {head && <div className={styles.head}>{head}</div>}
            <div
                tabIndex={1}
                onBlur={(e) => {
                    if (e.currentTarget.contains(e.relatedTarget as Node)) return;
                    setIsVisibleOptionsList(false);
                }}
                className={`${stylesSorting.child_range} ${styles.filter_container}`}
            >
                <div
                    onClick={() =>
                        onClickSelectedOption
                            ? onClickSelectedOption(selectedOption)
                            : setIsVisibleOptionsList((prevstate) => !prevstate)
                    }
                    className={styles.selector}
                    title={title}
                    data-current_value={selectedOption}
                >
                    <CurrentOption />
                </div>
                {isVisibleOptionsList && (
                    <div className={styles.options_list}>
                        {newOptions?.map((element, index) => {
                            let title;
                            const isCurrent = (element: TPartition | PartitionName | string) => {
                                let cond1, cond2, cond3;

                                if (typeof element !== "string") {
                                    const subpartitions = Object.values(element.subpartitions).filter(
                                        (val) => val.name && isEqualsWithEqualsSymbol(val.name)
                                    );
                                    cond2 = !!subpartitions.length;
                                    cond3 = element.name && isEqualsWithEqualsSymbol(element.name);
                                } else cond1 = element && isEqualsWithEqualsSymbol(element);

                                return !!cond1 || !!cond2 || !!cond3 || false;
                            };

                            if (typeof element !== "string") {
                                if ("subpartitions" in element) {
                                    const staticNames = element.name.split("=");
                                    return (
                                        <Filter
                                            key={index}
                                            prefix="— "
                                            title="Отобразить подразделы"
                                            options={Object.values(element.subpartitions)}
                                            selectedOption={
                                                selectedNestedOption ? selectedNestedOption : element.name
                                            }
                                            isStaticOption={staticNames[1]}
                                            className={isCurrent(element) ? styles.current_filter : ""}
                                            onClickSelectedOption={() => {
                                                onClick && onClick(element.name);
                                            }}
                                            onClick={(value) => {
                                                onClickSubpartition &&
                                                    onClickSubpartition(value, element.name);
                                            }}
                                        />
                                    );
                                }
                                title = element?.name;
                            } else title = element;

                            return (
                                <Option
                                    key={index}
                                    value={title}
                                    title={`${prefix ? prefix : ""} ${title?.split("=")[1]}`}
                                    onClick={onClick}
                                    className={isCurrent(title) ? styles.current : ""}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
