import $ from "jquery";
import { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { DEFAULT_PARAMS_SORTING } from "../assets/data/DEFAULT_DATA";
import URL_COLLECTION from "../assets/data/URL";
import styles from "../css/Searcher.module.css";

const Searcher = (props) => {
    const admin_url = URL_COLLECTION.sites.admin;
    const [searchParams] = useSearchParams();
    const params = searchParams.size === 0 ? DEFAULT_PARAMS_SORTING : Object.fromEntries(searchParams);

    const [value, setValue] = useState("");
    const { pathname } = useLocation();

    const onKeyDownCheck = (event) => {
        const val = event.target.value;
        switch (event.keyCode) {
            case 13:
                if (val) onSearch();
                break;
            default:
                break;
        }
    };

    function onSearch() {
        if (!value) return;
        params.search = value;
        if (["/recycle", "/news", "/adv"].includes(pathname)) window.location.search = `${$.param(params)}`;
        else window.location.href = `${admin_url}news/?${$.param(params)}`;
    }

    return (
        <div className={styles.searcher_area}>
            <input
                className={styles.search_input}
                title="Введите параметр, по которому необходимо найти запись"
                placeholder="Искать запись.."
                type="text"
                value={value}
                onKeyDown={onKeyDownCheck}
                onCut={onSearch}
                onChange={(event) => setValue(event.target.value)}
            ></input>
            <button onClick={onSearch} title="Искать" className={styles.search_button}></button>
        </div>
    );
};
export default Searcher;
