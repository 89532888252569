import { useAppDispatch } from "../../app/hooks";
import { requestSiteData } from "../../features/main/mainSlice";
import { useRecords } from "../../routes/Records/RecordsProvider";
import styles from "./Sorting.module.css";

export default function ReloadClearButtons({}) {
    //@ts-ignore
    const { methods } = useRecords();
    const { loading_items, change_search_params } = methods;

    const dispatch = useAppDispatch();

    function onClickButtonClear() {
        change_search_params({ clear: true });
        dispatch(requestSiteData({}));
    }

    return (
        <div className={styles.child_range}>
            <button className={styles.reload} onClick={loading_items} title="Обновить" />
            <button className={styles.clear} onClick={onClickButtonClear} title="Очистить" />
        </div>
    );
}
