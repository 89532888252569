import $ from "jquery";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAllSiteData } from "../mainSlice";
import Keyword from "./Keyword";
import { TypeKeyword } from "./Keywords.interface";
import style from "./Keywords.module.scss";

interface IKeywords {
    isActions?: boolean;
    searchdata?: string;
    onClick?: (text: string) => void;
}

function Keywords({ searchdata = "", onClick, isActions = true }: IKeywords) {
    const { keywords, status } = useSelector(getAllSiteData);

    const [isView, setIsView] = useState(false);

    isActions &&
        (function (elementid: string) {
            $(function () {
                function scrollHorizontally(e: any) {
                    const target = document.getElementById(elementid);
                    if (!target) return;

                    const delta = Math.max(-1, Math.min(1, e.deltaY || -e.detail));

                    target.scrollLeft -= delta * 10; // Multiplied by 10
                    e.preventDefault();
                }
                if (document.getElementById(elementid)?.addEventListener) {
                    // IE9, Chrome, Safari, Opera
                    document
                        .getElementById(elementid)
                        ?.addEventListener("mousewheel", scrollHorizontally, false);
                    // Firefox
                    document
                        .getElementById(elementid)
                        ?.addEventListener("DOMMouseScroll", scrollHorizontally, false);
                }
            });
        })("row_keywords");

    const searchValues = (searchparam: string, arrayvalues: TypeKeyword[] = []) => {
        const newArray = [...arrayvalues];
        const arrParams = searchparam?.split(" ");
        const lastElement = arrParams[arrParams?.length - 1];

        if (!lastElement.trim()) return;

        const find = function (arr: TypeKeyword[], find: string) {
            return arr.filter(function (value) {
                const { keyword } = value;
                const regexp = new RegExp(`${find}`, "gi");
                return keyword.match(regexp);
            });
        };
        const findedElements = find(newArray, lastElement) || [];
        return mapKeywords(findedElements, false, style.findedElement);
    };

    const mapKeywords = (keywordsArray: TypeKeyword[], isLink = true, className = "") =>
        keywordsArray?.map((element, index) => (
            <Keyword data={element} key={index} isLink={isLink} className={className} onClick={onClick} />
        ));

    return (
        <>
            <span className={style.insc}>1.Ключевые слова</span>
            <div className={style.keywords}>
                <div id="row_keywords" className={style.keywords_search_row}>
                    {searchValues(searchdata, keywords)}
                </div>
                <button
                    className={style.keywords_view_btn}
                    onClick={() => {
                        setIsView(!isView);
                    }}
                >
                    {isView ? "Скрыть" : "Отобразить все ключевые слова"}
                </button>
                <div className={`${style.keywords_meta} ${isView ? style.keywords_meta_view : ""}`}>
                    <div className={style.keywords_title}>Существующие ключевые слова:</div>
                    <div className={style.keywords_wrapper}>{isView && mapKeywords(keywords, false)}</div>
                </div>
            </div>
        </>
    );
}
export default Keywords;
