import { PartitionName } from "../../features/main/Partitions/partitions.interface";
import { currentDateTime } from "../../utils/functions/DateTime";
import randomnumber from "../../utils/functions/randomnumber";

/* Вход в аккаунт */
const DEFAULT_USER_DATA = {
    id: "1",
    login: "",
    password: "",
    permissions: ["analyze"],
    roles: ["admin"],
    entry: "off",
};

/* создание/редактирование записи */
const createadv = {
    partition: "Main=Основной", // раздел рекламы
    site: "clubok24.ru", //целевой сайт
    titleAdv: "", // заголовок рекламы
    linkAdv: "", // ссылка на рекламу
    adv_admin: "", // cостояние рекламы (вкл, выкл)
    md5: "", // ссылки на медиа с данного сайта
    path: "adv", // целевая таблица для загрузок
    checked: 1, // состояние рекламы (не в корзине, в корзине)
    position: ["random"], // позиции для вывода рекламы
    footnote: "", // подпись к рекламе
    timestamp: "", // время и дата создания рекламы
    view: "", // отображаемые пользователям просмотры
    averageColor: "", // средний цвет главной картинки
    note: "", // заметки администратора
    insc_ads: 1, // подпись "реклама" (вкл, выкл)
    is_trash: 0, // тип контента (треш, не треш и т.п.)
};
const createnews = {
    titleNews: "",
    partition: "Novosti=Новости",
    subpartition: "",
    md5: "",
    descNews: "",
    title: "",
    keywords: "",
    desc: "",
    date: currentDateTime("date"),
    datetime: currentDateTime("datetime"),
    path: "news",
    link: "",
    isVideoFrame: 1,
    view: randomnumber(1000, 2800),
    rating: [],
    site: "clubok24.ru",
    averageColor: "",
};
/* Заполняемые поля */
const DEFAULT_DATA = {
    currentItem: { id: "", mediaReplace: "noreplace" }, // если запись редактируется, то id равен id записи, если была замена медиа, то mediaReplace равен новым медиа
    createadv,
    createnews,
};

const PAGE_TITLES = {
    news: "Управление новостями",
    adv: "Рекламные баннеры",
    recycle: "Удалённые записи",
};

// данные из скриптов main.php и statistics.php


/* формат => => => => => ______Значение=Наименование______ <= <= <= <= <=*/

/* позиции рекламы на сайте clubok24.ru */
const POSITIONS_ADV = [
    "random=Случайно",
    /*     "left=Левая колонка (авито)", */
    "leftbottom=Левая колонка (только ПК)",
    "right=Правая колонка (только ПК)",
    "centerPost=В середине поста",
    "innerPost=Каждый 5-й блок в общей массе записей",
    "aftercontent=В подвале сайта",
];
/* редактируемые сайты */
const SITES: PartitionName[] = [
    "clubok24.ru=clubok24.ru",
    "video.clubok24.ru=video.clubok24.ru",
    "super-story.ru=super-story.ru",
];
/* типы рекламы */
const TYPES_ADV = ["0=Основной", "1=Треш", "2=Новости", "3=Треш/основной"];
/* рамка 'смотреть видео' или 'читать статью' */
const RECORD_FRAMES = ["0=ВЫКЛ", "1=видео", "2=текст"];
/* ограничение по количеству выводимых записей */
const LIMITS_ON_THE_PAGE = ["6=6", "21=21", "51=52", "102=102"];

//only record's page
let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
let yyyy = today.getFullYear();
let todayString = yyyy + "-" + mm + "-" + dd;

const FIRST_DATE = "2023-02-09";
const DEFAULT_PARAMS_SORTING = {
    value: "timestamp",
    partition: "all",
    subpartition: "all",
    position: "all",
    to: "up",
    limit: "21",
    state: "1",
    frame: "all",
    site: "clubok24.ru",
    type_content: "0",
    filter_period: "statistics",
    table: "news",
    period_from: `${new Date(FIRST_DATE).getTime()}`,
    period_to: `${new Date(todayString).getTime()}`,
};

export {
    DEFAULT_DATA,
    DEFAULT_PARAMS_SORTING,
    DEFAULT_USER_DATA,
    FIRST_DATE,
    LIMITS_ON_THE_PAGE,
    PAGE_TITLES,
    POSITIONS_ADV,
    RECORD_FRAMES,
    SITES, today, TYPES_ADV
};

