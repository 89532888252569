import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import style from "../../css/Pages.module.css";
import MessageItem from "./MessageItem";
import { requestMessages, selectMessages } from "./messagesSlice";

export default function Messages() {
    const { messages, status } = useAppSelector(selectMessages);
    const dispatch = useAppDispatch();

    const getMessages = () =>
        dispatch(
            requestMessages({
                type: "getMessages",
            })
        );

    //auto-renew in 60s
    useEffect(() => {
        let timer: NodeJS.Timeout;
        getMessages();
        timer = setInterval(getMessages, 60000);
        return () => timer && clearInterval(timer);
    }, []);

    function messageItems() {
        if (!messages) return;
        if (!Array.isArray(messages)) return;
        return messages.map((elem) => <MessageItem key={elem.id} messageData={elem} />);
    }

    return (
        <>
            <div className={style.title_opt}>Сообщения пользователей</div>
            <div className={style.content_oneitemPage}>
                <div className={style.messages_wrapper}>
                    {status === "loading" ? "Загрузка..." : ""}
                    {messages.length === 0 && status === "idle" ? (
                        <h3>Сообщения отсутствуют</h3>
                    ) : (
                        messageItems()
                    )}
                </div>
            </div>
        </>
    );
}
