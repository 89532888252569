import axios from "axios";
import URL_COLLECTION from "../../assets/data/URL";
import { Comment, IRequestComments } from "./comments.interface";
const url_comments = URL_COLLECTION.scripts.main.comments;

export const fetchComments = async (args?: IRequestComments): Promise<Comment[]> => {
    const { type, id } = args || {};
    const isId = id ? `&id=${id}` : "";
    try {
        const response = await axios.get<Record<number, Comment>>(
            `${url_comments}?type=${type || "get"}${isId}`
        );
        return Object.values(response.data || {});
    } catch (e) {
        console.error(e);
        return [];
    }
};
