import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import InputText from "../../../../components/Input/InputText";
import transliterate from "../../../../utils/functions/transliterator";
import { getCurrentSite, requestSiteData } from "../../mainSlice";
import { validatePartition } from "../partitions.helpers";
import { TParamsPartitions } from "../partitions.interface";
import style from "../Partitions.module.scss";

interface IAddPartition {
    title: string;
    value: string;
    paramsPartitions: TParamsPartitions;
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void;
    setIsSaveParts: Dispatch<SetStateAction<string | boolean>>;
}

type OnAddCallBack = {
    data?: {
        message: string;
    };
};

export default function AddPartition({
    title,
    value,
    paramsPartitions,
    onChange,
    setIsSaveParts,
}: IAddPartition) {
    const [error, setError] = useState<boolean | string>(false);
    const currentSite = useAppSelector(getCurrentSite);
    const dispatch = useAppDispatch();

    function onAdd() {
        const iteratedValue = value.trim();
        const isValidate = validatePartition(iteratedValue);
        if (typeof isValidate === "string") return setError(isValidate);

        setError(false);

        const transliteratedWord = transliterate(iteratedValue);

        const callback = ({ data }: OnAddCallBack) => {
            const error = () => {
                setIsSaveParts(" - Ошибка сохранения данных");
                setTimeout(() => setIsSaveParts(false), 8000);
            };
            const ok = () => {
                setIsSaveParts(" - Изменения сохранены!");
                setTimeout(() => setIsSaveParts(false), 8000);
            };

            if (!data) return error();

            if (data?.message) {
                const status_message = data?.message?.split(",") || [];
                if (status_message && status_message[1] === "ok") ok();
            } else error();
        };

        dispatch(
            requestSiteData({
                type: "set",
                callback,
                data: {
                    table: paramsPartitions.table,
                    site: currentSite,
                    parent_id: paramsPartitions.parent_id,
                    current: `${transliteratedWord}=${iteratedValue}`,
                },
            })
        );
    }

    return (
        <div className={style.partition_create}>
            <div>
                <InputText
                    //@ts-ignore
                    errorMessage={error}
                    //@ts-ignore
                    onChange={onChange}
                    name="titleAdv"
                    value={value}
                    max={200}
                    type="text"
                    required
                    input_insc={title}
                    input_plc="Новая рубрика..."
                />
            </div>
            <button onClick={onAdd} type="button" name="partitions" className={style.partition_create_button}>
                Добавить
            </button>
        </div>
    );
}
