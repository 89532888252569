import $ from "jquery";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getCurrentSite, requestSiteData } from "../../mainSlice";
import style from "../Partitions.module.scss";
import { DEFAULT_PARAMS_PARTITIONS } from "../defaults";
import { TParamsPartitions } from "../partitions.interface";

interface IEditButtons {
    isSaveParts: boolean | string;
    isEdit: boolean;
    isRestore: boolean;
    paramsPartitions: TParamsPartitions;

    onDelete: () => void;
    onEdit: () => void;
    onAdd: () => void;

    setValue: Dispatch<SetStateAction<string>>;
    setIsRestore: Dispatch<SetStateAction<boolean>>;
    setIsEdit: Dispatch<SetStateAction<boolean>>;
    setParamsPartitions: Dispatch<SetStateAction<TParamsPartitions>>;
}

export default function EditButtons({
    isEdit,
    isSaveParts,
    isRestore,
    paramsPartitions,

    onAdd,
    onEdit,
    onDelete,

    setIsEdit,
    setIsRestore,
    setParamsPartitions,
    setValue,
}: IEditButtons) {
    const currentSite = useAppSelector(getCurrentSite);
    const dispatch = useAppDispatch();

    function onreset_params() {
        setValue("");
        $(`.el_pn`).removeClass(style.pe_deleted_partition);
        $(`.el_pn`).removeClass(style.partition_elem_editable);

        setIsRestore(false);
        setIsEdit(false);
        setParamsPartitions(DEFAULT_PARAMS_PARTITIONS);
    }
    function onsave_changes() {
        const checkTarget = (target: string[]) => Array.isArray(target);

        const prev = checkTarget(paramsPartitions?.prevent);
        const curr = checkTarget(paramsPartitions?.current);
        const recy = checkTarget(paramsPartitions?.recycle);

        if ((prev && curr) || recy) {
            dispatch(
                requestSiteData({
                    type: "change",
                    callback: onreset_params,
                    data: {
                        table: paramsPartitions.table,
                        parent_id: paramsPartitions.table,
                        site: currentSite,

                        prevent: prev ? paramsPartitions?.prevent.join(",") : false,
                        current: curr ? paramsPartitions?.current.join(",") : false,
                        recycle: recy ? paramsPartitions?.recycle.join(",") : false,
                    },
                })
            );
        }
    }

    useEffect(() => {
        if (isSaveParts) onreset_params();
    }, [isSaveParts]);

    return (
        <div className={style.edit_buttons}>
            {(isEdit || isRestore) && (
                <div className={style.pe_save_cancel}>
                    <div className={style.pe_wrapper_btn_cs}>
                        <div className={style.pe_back}></div>
                        <div
                            onClick={onreset_params}
                            title="Отменить изменения"
                            className={`${style.pe_cancel_btn} ${style.pe_btn}`}
                        ></div>
                    </div>
                    <div className={style.pe_wrapper_btn_cs}>
                        <div className={style.pe_back}></div>
                        <div
                            onClick={onsave_changes}
                            title="Сохранить изменения"
                            className={`${style.pe_save_btn} ${style.pe_btn}`}
                        ></div>
                    </div>
                </div>
            )}
            <div
                onClick={onDelete}
                title="Удалить/Восстановить рубрики"
                className={`${style.pe_restore_btn} ${style.pe_btn} ${isRestore ? style.pe_active : ""}`}
            />
            <div
                onClick={onEdit}
                title="Редактировать рубрики"
                className={`${style.pe_edit_btn} ${style.pe_btn} ${isEdit ? style.pe_active : ""}`}
            />
            <div
                onClick={onAdd}
                title="Добавить рубрику"
                className={`${style.pe_create_btn} ${style.pe_btn} ${isEdit ? style.pe_active : ""}`}
            />
        </div>
    );
}
