import { useAppSelector } from "../../../app/hooks";
import { today } from "../../../assets/data/DEFAULT_DATA";
import { getStatistics } from "../mainSlice";
import style from "./Statistics.module.css";
import StatisticsItem from "./StatisticsItem";

export default function Statistics() {
    const { data, status } = useAppSelector(getStatistics);

    const { viewsCount, commentsCount, ratingCount, createdAdvCount, createdNewsCount } = data || {};

    return (
        <div className={style.statistics_wrapper}>
            <div title={`Статистика за ${today}`} className={style.head}>
                Статистика за сутки
            </div>
            {status === "loading" ? (
                <div className={style.row}>Загрузка...</div>
            ) : (
                <>
                    <div className={style.row}>
                        <StatisticsItem
                            heading="Просмотры записей"
                            title="Общее количество просмотров за сутки"
                            value={viewsCount}
                        />
                        <StatisticsItem
                            heading="Оценки"
                            title="Общее количество оценок за сутки"
                            value={ratingCount}
                        />
                        <StatisticsItem
                            heading="Комментарии"
                            title="Общее количество комментариев за сутки"
                            value={commentsCount}
                        />
                    </div>
                    <div className={style.row}>
                        <StatisticsItem
                            heading="Создано записей"
                            title="Общее количество созданных записей за сутки"
                            value={createdNewsCount}
                        />
                        <StatisticsItem
                            heading="Создано рекламных записей"
                            title="Общее количество созданных рекламных записей за сутки"
                            value={createdAdvCount}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
