import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../app/types";
import URL_COLLECTION from "../../assets/data/URL";
import { UserInfo } from "./auth.interface";

const ADMIN_SITE = URL_COLLECTION.sites.admin;
const AUTH_URL = URL_COLLECTION.scripts.main.auth;

const authApiPath = AUTH_URL.replace(ADMIN_SITE, "");

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: ADMIN_SITE,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).user.userToken;
            
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                return headers;
            } else {
                headers.delete("authorization");
            }
        },
    }),
    endpoints: (builder) => ({
        getUserDetails: builder.query<UserInfo, string>({
            query: () => ({
                url: authApiPath,
                method: "GET",
            }),
        }),
    }),
});

export const { useGetUserDetailsQuery } = authApi;
