// features/auth/authSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import { RootState } from "../../app/types";
import { IInitialStateAuth } from "./auth.interface";
import { userLogin } from "./authActions";
import { tokenName, userNameItem } from "./data/Variables";

const cookies = new Cookies();
const userToken = cookies.get(tokenName);
const userInfo = localStorage.getItem(userNameItem) || {};

const initialState: IInitialStateAuth = {
    loading: false,
    userInfo: typeof userInfo === "string" ? JSON.parse(userInfo) : {}, // for user object
    userToken, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            cookies.remove(tokenName); // deletes token from storage
            state.loading = false;
            state.userInfo = {};
            state.userToken = null;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userInfo = payload;
                state.userToken = payload.userToken;
            })
            .addCase(userLogin.rejected, (state, { payload }: PayloadAction<any>) => {
                state.loading = false;
                state.error = payload;
            });
    },
});

export const getUser = (state: RootState) => state.user;

export const { logout } = authSlice.actions;

export default authSlice.reducer;
