import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/types";
import { fetchAllSiteData, fetchStatistics } from "./main.api";
import { InitialStateMain } from "./main.defaults";
import { ResponseGetAllSiteData, TypeGetAllSiteData, TypeGetStatistics } from "./main.interface";
import { Statistics } from "./Statistics/Statistics.interface";

export const requestStatistics = createAsyncThunk<Statistics, TypeGetStatistics>(
    "statistics/requestStatistics",
    async (type: TypeGetStatistics) => await fetchStatistics(type)
);

export const requestSiteData = createAsyncThunk<ResponseGetAllSiteData, TypeGetAllSiteData>(
    "main/requestSiteData",
    async (args) => await fetchAllSiteData(args)
);

export const mainSlice = createSlice({
    name: "main",
    initialState: InitialStateMain,
    reducers: {
        changeSite: (state, action: PayloadAction<any>) => {
            state.currentSite = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            //request statistics
            .addCase(requestStatistics.pending, (state) => {
                state.statistics.status = "loading";
            })
            .addCase(requestStatistics.rejected, (state) => {
                state.statistics.status = "failed";
            })
            .addCase(requestStatistics.fulfilled, (state, action) => {
                state.statistics.status = "idle";
                state.statistics.data = action.payload;
            })
            //request all site data (keywords, ads/news partitions, new comments?)
            .addCase(requestSiteData.pending, (state) => {
                state.allSiteData.status = "loading";
            })
            .addCase(requestSiteData.rejected, (state) => {
                state.allSiteData.status = "failed";
            })
            .addCase(requestSiteData.fulfilled, (state, { payload }) => {
                state.allSiteData.status = "idle";
                state.allSiteData.keywords = Object.values(payload.all_keywords || {});
                state.allSiteData.ads_sections = Object.values(payload.all_ads_selectors || {});
                state.allSiteData.partitions = Object.values(payload.all_parts || {});
                state.allSiteData.not_viewed_comments = payload.not_viewed_comments;
                state.allSiteData.not_viewed_messages = payload.not_viewed_messages;
            });
    },
});

export const getStatistics = (state: RootState) => state.main.statistics;
export const getAllSiteData = (state: RootState) => state.main.allSiteData;
export const getCurrentSite = (state: RootState) => state.main.currentSite;

export const { changeSite } = mainSlice.actions;

export default mainSlice.reducer;
