import { FocusEvent, useEffect, useRef } from "react";
import type { TSubpartition, TSubpartitions } from "../partitions.interface";
import style from "../Partitions.module.scss";
import PartitionsWrapper, { onChangeSubpartFeatures, SubpartFeatures } from "../PartitionsWrapper";

interface ISubPartitions {
    parent_id: string;
    subpartitions: TSubpartitions | false;
    subpartFeatures: SubpartFeatures;
    onChangeSubpartFeatures: (features: onChangeSubpartFeatures) => void;
}

export default function SubPartitions({
    parent_id,
    subpartitions,
    onChangeSubpartFeatures,
    subpartFeatures,
}: ISubPartitions) {
    const { add_container_opened, parent_id: openedSubpartID } = subpartFeatures;
    const isView = openedSubpartID !== false && openedSubpartID === parent_id;

    const fullContainerRef = useRef<HTMLDivElement | null>(null);

    const arr_subpartitions: TSubpartition[] = subpartitions ? Object.values(subpartitions) : [];

    const setPositionInScreenView = (target: HTMLElement) => {
        const { innerHeight, innerWidth } = window;

        const { left, right, top, bottom, height, width } = target.getBoundingClientRect();
        let nextLeft = 0,
            nextTop = 0;

        if (right > innerWidth) nextLeft = (right - innerWidth) * -1;
        else if (right < 0) nextLeft = width;

        target.style.left = `${nextLeft}px`;
    };

    const onSubTitle = () => {
        onChangeSubpartFeatures({
            key: "parent_id",
            state: isView ? false : parent_id,
        });

        if (!fullContainerRef.current) return;
        fullContainerRef.current.style.left = "unset";
        fullContainerRef.current.style.top = "unset";

        setPositionInScreenView(fullContainerRef.current);
    };

    const onBlurSubPartitions = (e: FocusEvent<HTMLDivElement, Element>) => {
        if (!e.currentTarget.contains(e.relatedTarget as Node))
            onChangeSubpartFeatures({
                key: "parent_id",
                state: false,
            });
    };
    useEffect(() => {
        if (!isView || !fullContainerRef.current) return;
        fullContainerRef.current.getBoundingClientRect().right > window.innerWidth &&
            setPositionInScreenView(fullContainerRef.current);
    }, [isView, fullContainerRef.current]);

    return (
        <div
            data-is_view={isView}
            tabIndex={1}
            onBlur={onBlurSubPartitions}
            className={style.subpartitions_container}
        >
            <div
                title={`Количество связанных подразделов (${isView ? "закрыть" : "открыть"})`}
                className={style.subpartitions_title}
                onClick={onSubTitle}
            >
                {subpartitions ? (isView ? "x" : arr_subpartitions.length) : isView ? "x" : "+"}
            </div>

            <div
                ref={fullContainerRef}
                style={{
                    visibility: isView ? "visible" : "hidden",
                }}
                className={style.subpartitions_full_container}
            >
                <PartitionsWrapper
                    parent_id={parent_id}
                    isInfoContainer={false}
                    isSubpartitions={false}
                    data={arr_subpartitions}
                    fetching={true}
                    table="subpartitions"
                    title="Подразделы рубрики сайта"
                    titleInput="Добавить новый подраздел"
                    //TODO: Доделать фичу
                    isAddContainerAlwaysOpened={true}
                />
            </div>
        </div>
    );
}
