import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, StatusReducer } from "../../app/types";
import { fetchComments } from "./comments.api";
import { Comment, IRequestComments } from "./comments.interface";

export interface IInitialStateComments {
    comments: any[];
    status: StatusReducer;
}

const initialState: IInitialStateComments = {
    comments: [],
    status: "idle",
};
export const requestComments = createAsyncThunk<Comment[], IRequestComments>(
    "comments/requestComments",
    async (args?: IRequestComments) => {
        return await fetchComments(args);
    }
);


export const commentsSlice = createSlice({
    name: "comments",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //handle all comments
            .addCase(requestComments.pending, (state) => {
                state.status = "loading";
            })
            .addCase(requestComments.rejected, (state) => {
                state.status = "failed";
            })
            .addCase(requestComments.fulfilled, (state, action) => {
                state.status = "idle";
                state.comments = action.payload;
            });
    },
});

export const selectComments = (state: RootState) => state.comments.comments;

export default commentsSlice.reducer;
